import React from 'react'

const Confetti = () =>  {
	return (
		<svg width="328px" height="130px" viewBox="0 0 328 130" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<g id="Symbols" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
				<g id="The-Deal" transform="translate(0.000000, 2.000000)">
					<g id="Stacked-Group">
						<g id="Top">
							<g id="Confetti" transform="translate(0.000000, 10.000000)">
								<circle id="Dot-Copy" fill="#EA535D" cx={117} cy={36} r={3} />
								<circle id="Dot-Copy-9" fill="#BAEBFF" cx={42} cy={50} r={3} />
								<circle id="Dot-Copy-10" fill="#FDCC34" cx={3} cy={111} r={3} />
								<circle id="Dot-Copy-2" fill="#D8D8D8" cx={210} cy={33} r={3} />
								<circle id="Dot-Copy-3" fill="#FDCC34" cx={307} cy={70} r={3} />
								<circle id="Dot-Copy-5" fill="#EA535D" cx={274} cy={38} r={4} />
								<circle id="Ring-Copy" stroke="#FDCC34" strokeWidth={3} cx={322} cy={112} r={4} />
								<rect id="Rectangle" fill="#225DFF" x={256} y={7} width={2} height={13} rx={1} />
								<path d="M257,7 C257.552285,7 258,7.44771525 258,8 L258,19 C258,19.5522847 257.552285,20 257,20 C256.447715,20 256,19.5522847 256,19 L256,8 C256,7.44771525 256.447715,7 257,7 Z" id="Rectangle-Copy-3" fill="#225DFF" transform="translate(257.000000, 13.500000) rotate(-270.000000) translate(-257.000000, -13.500000) " />
								<rect id="Rectangle" fill="#60E8FA" x={60} y={0} width={2} height={14} rx={1} />
								<path d="M61,5.68434189e-14 C61.5522847,5.67419658e-14 62,0.44771525 62,1 L62,13 C62,13.5522847 61.5522847,14 61,14 C60.4477153,14 60,13.5522847 60,13 L60,1 C60,0.44771525 60.4477153,5.69448719e-14 61,5.68434189e-14 Z" id="Rectangle-Copy-3" fill="#60E8FA" transform="translate(61.000000, 7.000000) rotate(-270.000000) translate(-61.000000, -7.000000) " />
								<rect id="Rectangle" fill="#FDCC34" x={144} y={20} width={1} height={10} rx="0.5" />
								<path d="M144.5,20 C144.776142,20 145,20.2238576 145,20.5 L145,29.5 C145,29.7761424 144.776142,30 144.5,30 C144.223858,30 144,29.7761424 144,29.5 L144,20.5 C144,20.2238576 144.223858,20 144.5,20 Z" id="Rectangle-Copy-3" fill="#FDCC34" transform="translate(144.500000, 25.000000) rotate(-270.000000) translate(-144.500000, -25.000000) " />
							</g>
							<circle id="Ring-Copy-3" stroke="#EA535D" strokeWidth={3} cx={18} cy={67} r={7} />
							<circle id="Ring-Copy-2" stroke="#BAEBFF" strokeWidth={3} cx={176} cy={5} r={5} />
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default Confetti
